import React from "react"
import Nav from "../components/Nav"
import Head from "../components/Head"
import Layout from "../components/layout"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { graphql } from "gatsby"
//import { node } from "prop-types"


export default ({ data }) => (
    <div>
        <Layout>
        <Head />
        <Nav />
        <Header title="Testing Import via GraphQL"/>
        <p style={{paddingBottom: "20px", textAlign: "center"}}>Currently testing importing from WordPress via GraphQL. I'm using my <a href="https://dakishatest.wpcomstaging.com" target="_blank" rel="noopener noreferrer">test site</a> and this is just the 1.0 version of this import :)</p>
            {data.allWordpressPost.edges.map(({ node }) => (
            <div>      
            <h3>{node.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
            ))}
        <div style={{textAlign: "center"}}><Footer /></div>
        </Layout>
    </div>
)

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
        }
      }
    }
  }
  `